<script lang="ts">
	import { onMount, tick } from "svelte";
	import Shuffle from "shufflejs";

	import SvgIcon from "./svg-icon.svelte";
	
	export let activeTopic: string | null = null;

	let container: HTMLElement;
	let topics: string[] = [];
    let shuffle: Shuffle;

	onMount(() => {
		let allTopics = [...container.querySelectorAll<HTMLElement>(".topic-tag")].map((el) => el.innerText);
        topics = [...new Set(allTopics)];
		topics.sort();

		shuffle = new Shuffle(container, {
			itemSelector: ".article-list-item",
		});
		
		if (activeTopic != null && activeTopic !== "") {
			filter(activeTopic);
		}
	});

    function filter(topic: string) {
        activeTopic = topic;
        shuffle.filter(topic);
    }
</script>

<div class="topic-filters mb-4">
	<div class="field has-addons is-hidden-touch is-hidden-desktop-only">
        <p class="control">
            <button class="button is-medium" class:is-active={activeTopic === null} on:click={() => filter(null)}>Alle Themen</button>
        </p>
		{#each topics as topic}
			<p class="control">
				<button class="button is-medium" class:is-active={activeTopic === topic} on:click={() => filter(topic)}>{topic}</button>
			</p>
		{/each}
	</div>
	<div class="field is-horizontal is-hidden-widescreen">
		<div class="field-label is-medium">
			<label class="label pt-2">Themenfilter</label>
		</div>
		<div class="field-body">
			<div class="field">
				<div class="control">
					<div class="select is-fullwidth is-medium">
						<select bind:value={activeTopic} on:change={(e) => filter(e.target.value)}>
							<option value={null}>Alle Themen</option>
							{#each topics as topic}
								<option value={topic}>{topic}</option>
							{/each}
						</select>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="article-list columns is-2-mobile is-6-widescreen is-multiline is-mobile" bind:this={container}>
	<slot></slot>
</div>

<style>
	:global(svelte-retag) {
		display: contents;
	}

	:global(topics-filter) {
		flex-grow: 1;
		display: contents !important;
	}
</style>
