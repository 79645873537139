import "../Styles/index.scss";

import svelteRetag from "svelte-retag";
import tippy from "tippy.js";
import { ScrollDirectionManager } from "scroll-direction-manager";

import CommentWidget from "./comment-widget.svelte";
import TopicsFilter from "./topics-filter.svelte";

const CollapsedClass = "is-collapsed";
const OpenClass = "is-open";

let nav = document.querySelector<HTMLElement>(".header-nav");
let header = document.querySelector<HTMLElement>("header");
document.querySelector("#navOpen")?.addEventListener("click", function () {
	if (header?.classList.contains(CollapsedClass)) {
		nav?.classList.toggle(OpenClass);
	} else {
		nav?.classList.add(OpenClass)
	}
});
document.querySelector("#navClose")?.addEventListener("click", () => nav?.classList.remove(OpenClass));

for (let label of document.querySelectorAll(".header-nav .nav-level-1 .nav-label")) {
	label.addEventListener("click", function (e) {
		var node = (e.currentTarget as HTMLElement).parentElement!;
		for (let sibling of node.parentElement?.children ?? []) {
			if (sibling !== node) {
				sibling.classList.remove(OpenClass);
			}
		}
		node.classList.toggle(OpenClass);
	});
}

new ScrollDirectionManager({
	initialOffset: 145,
	onDirectionChange: direction => {
		if (direction === "down") {
			header?.classList.add(CollapsedClass);

			let logo = header?.querySelector<HTMLElement>(".logo");
			logo?.setAttribute("data-src-full", logo?.getAttribute("src")!);
			logo?.setAttribute("src", logo.getAttribute("data-src-min")!);
		}
		else {
			header?.classList.remove(CollapsedClass);

			let logo = header?.querySelector<HTMLElement>(".logo");
			logo?.setAttribute("src", logo.getAttribute("data-src-full")!);
		}
	}
});

for (let figcaption of document.querySelectorAll<HTMLElement>("table + figcaption")) {
	let table = figcaption.previousElementSibling;
	figcaption.style.width = `${table?.clientWidth}px`;
}

tippy(".footnote-reference", {
	theme: "light",
	allowHTML: true,
	interactive: true,
	appendTo: document.body,
	content(reference) {
		const id = reference.getAttribute("data-template");
		if (id == null) {
			return "";
		}
		const template = document.getElementById(id);
		return template?.innerHTML ?? "";
	},
});

svelteRetag({
	component: CommentWidget,
	tagname: "comment-widget",
});

svelteRetag({
	component: TopicsFilter,
	tagname: "topics-filter",
});

if (document.querySelector("article-navigation") != null) {
	import("./article-navigation.svelte").then(m => {
		svelteRetag({
			component: m.default,
			tagname: "article-navigation",
		});
	});
}

if (document.querySelector("contact-form") != null) {
	import("./contact-form.svelte").then(m => {
		svelteRetag({
			component: m.default,
			tagname: "contact-form",
		});
	});
}
